<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">角色列表</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center margin-10-0">
				<router-link :to="{name:'store.role/add'}" v-auth="'store.role/add'">
					<el-button type="primary" size="mini">添加角色</el-button>
				</router-link>
			</div>
			<el-table border class="mt-3" :data="lists" style="width: 100%"
				>
				<el-table-column align="center" label="角色ID" prop="role_id">
				</el-table-column>
				<el-table-column align="center" label="角色名称" prop="role_name">
				</el-table-column>
				<el-table-column align="center" label="排序" prop="sort">
				</el-table-column>
				<el-table-column align="center" label="创建时间" prop="create_time">
				</el-table-column>
				<el-table-column align="center" label="操作" width="150">
					<template slot-scope="scope">
						<router-link :to="{name:'store.role/edit',params: {role_id:scope.row.role_id}}"
							v-auth="'store.role/edit'">
							<el-button type="text" size="mini" class="table-btn">修改</el-button>
						</router-link>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)" class="table-last-btn">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				search: {
					page: 1,
					s: 'store/store.role/index',
				}
			}
		},
		created() {
			this.getList();
		},
		methods: {
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该角色吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading() 
					this.axios({
						token: true,
						params: {
							s: 'store/store.role/delete',
							role_id: data.role_id,
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>

</style>
